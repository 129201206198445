import React, { useEffect, useRef, useState } from 'react';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import {
  BASE_SECTION_BACKGROUND_TYPES,
  OVERLAP_TYPE,
  RICH_IMAGE_TEXT_DEFAULT_WIDGET_OPTIONS,
} from './utils';
import { TextWidgetContainer } from './component/TextWidgetSection';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';

const RichTextWithImageWidget = (props: any) => {
  const {
    widgetContextState,
    widgetContextHelpers: { handleWidgetPropChange },
  } = useWidgetDndContextData();
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const textContainerRef = useRef<HTMLDivElement>(null);
  const { widgets, activeWidgetId } = widgetContextState;

  const activeWidget = widgets[(props as any).id];
  const activeWidgetProps = activeWidget?.props;
  const elementRef = useRef(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (entry.isIntersecting) {
          const visibleRatio = entry.intersectionRatio;
          setScale(1 + visibleRatio * 0.1);
        } else {
          setScale(1);
        }
      },
      {
        root: null, 
        threshold: Array.from({ length: 11 }, (_, i) => i * 0.1),
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  const widgetOptions = {
    ...RICH_IMAGE_TEXT_DEFAULT_WIDGET_OPTIONS,
    ...props.widgetOptions,
    ...activeWidgetProps,
  };
  const getWidthForTextContainer = (dimensions) => {
    const currentDimension = Number(dimensions.width?.replace('%', ''));
    return isMobile ? '100%' : 100 - currentDimension + '%';
  };

  const checkIfContentBigger = () => {
    const dimensions = widgetOptions?.imageSection?.desktop || {};
    const maxContentHeight = textContainerRef?.current?.offsetHeight;
    const imageActualHeight = Number(dimensions?.height?.replace('px', ''));
    return imageActualHeight === 300 ? true : maxContentHeight > imageActualHeight;
  };

  const getCurrentClass = (type) => {
    switch (type) {
      case 'zoomIn': {
        return 'zoomInOnHover';
      }
      case 'ambient': {
        return 'ambient';
      }
    }
  };

  function getOverlapPadding(currentPadding, type = 'bottom') {
    if (type === 'top') {
      if (currentPadding >= 40) return currentPadding;
      else return 40;
    } else if (type === 'bottom') {
      if (currentPadding >= 80) return currentPadding;
      else return 80;
    }
  }

  function handleSectionClick() {
    if (activeWidgetId) {
      handleWidgetPropChange(
        {
          subWidget: '',
          subWidgetIndex: -1,
          textGroupSection: widgetOptions?.textGroupSection,
        },
        activeWidgetId
      );
    }
  }

  const getStyleObject = () => {
    const baseConfig = widgetOptions?.baseConfig;
    const paddingY = baseConfig?.paddingY;
    const showOverlapPadding =
      baseConfig.overlapType === OVERLAP_TYPE.OVERLAP && checkIfContentBigger();
    const style = {
      paddingTop: showOverlapPadding ? getOverlapPadding(paddingY, 'top') : paddingY,
      paddingBottom: showOverlapPadding ? getOverlapPadding(paddingY) : paddingY,
      paddingLeft: baseConfig?.paddingX,
      paddingRight: baseConfig?.paddingX,
      marginTop: baseConfig?.marginTop,
      marginBottom: baseConfig?.marginBottom,
      ...(baseConfig?.backgroundType === BASE_SECTION_BACKGROUND_TYPES.color
        ? { background: baseConfig?.background }
        : { backgroundImage: `url(${baseConfig?.bgImage.url || ''})` }),
    };
    return style;
  };

  function getSectionContainerStyle() {
    const customClass = {
      position: 'relative',
    };
    const imageSection = widgetOptions?.imageSection;
    if (widgetOptions?.baseConfig?.overlapType === OVERLAP_TYPE.OVERLAP) {
      if (isMobile) {
        const isTop = imageSection?.mobile?.imageAlignment === 'top';
        return {
          ...customClass,
          top: isTop ? '20px' : '-20px',
        };
      } else {
        const isLeft = imageSection?.desktop?.imageAlignment === 'left';
        return {
          ...customClass,
          left: isLeft ? '20px' : '-20px',
        };
      }
    }
  }

  function getImageHeight(imageHeight) {
    const maxContentHeight = textContainerRef?.current?.offsetHeight;
    const imageActualHeight = Number(imageHeight?.replace('px', ''));
    const actualCalculatedHeight =
      maxContentHeight > imageActualHeight ? maxContentHeight : imageActualHeight;
    return actualCalculatedHeight;
  }

  const renderImageSection = () => {
    const dimensions = widgetOptions?.imageSection?.desktop || {};
    const mobileDimension = widgetOptions?.imageSection?.mobile || {};
    const actualCalculatedHeight = getImageHeight(dimensions.height);
    return (
      <div
        onClick={handleSectionClick}
        style={{
          width: isMobile ? '100%' : dimensions?.width,
          height: isMobile
            ? mobileDimension.height
            : actualCalculatedHeight + 4 + 'px',
        }}
        className="tw-overflow-hidden"
      >
        <img
          src={widgetOptions.imageSection?.url || '/assets/images/richTextWithImage.png'}
          style={{
            width: '100%',
            height: '100%',
            transition: widgetOptions?.imageSection?.animationType === 'onScroll' ? 'transform 0.2s ease-out' : '',
            transform: widgetOptions?.imageSection?.animationType === 'onScroll'  ? `scale(${scale})` : '',
            border:
              widgetOptions?.baseConfig?.showBorder &&
              widgetOptions?.baseConfig?.overlapType === OVERLAP_TYPE.OVERLAP
                ? '1px solid #e2e4e7'
                : '',
          }}
          className={`tw-object-cover 
        ${contentAnimationStyle[getCurrentClass(widgetOptions?.imageSection?.animationType)]}
        `}
        />
      </div>
    );
  };

  const renderSections = () => {
    const dimensions = widgetOptions?.imageSection?.desktop || {};
    const widthForTextContainer = getWidthForTextContainer(dimensions);
    return (
      <div
        style={{
          border:
            widgetOptions?.baseConfig?.showBorder &&
            widgetOptions?.baseConfig?.overlapType !== OVERLAP_TYPE.OVERLAP
              ? '1px solid #e2e4e7'
              : '',
          ...(getSectionContainerStyle() as any),
        }}
        className={`tw-flex ${isMobile ? 'tw-flex-col' : ''} tw-items-stretch 
       ${widgetOptions?.imageSection?.desktop.imageAlignment === 'right' ? 'tw-flex-row-reverse' : ''}
       ${widgetOptions?.imageSection?.mobile.imageAlignment === 'bottom' && isMobile ? 'tw-flex-col-reverse' : ''} 
       tw-w-[100%] tw-max-w-[1500px] `}
      >
        {renderImageSection()}
        <TextWidgetContainer
          widgetOptions={widgetOptions}
          textContainerRef={textContainerRef}
          getImageHeight={getImageHeight}
          width={widthForTextContainer}
          widgetId={activeWidgetId}
        />
      </div>
    );
  };

  return (
    <div ref={elementRef} style={getStyleObject()}>
      {widgetOptions?.baseConfig?.bgImage?.url &&
      widgetOptions?.baseConfig?.backgroundType ===
        BASE_SECTION_BACKGROUND_TYPES.image ? (
        <div
          className={`tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1] tw-bg-black`}
          style={{
            background: `rgba(0,0,0, ${(widgetOptions?.baseConfig?.bgImage?.opacity || 1) / 100 || 0.2})`,
          }}
        ></div>
      ) : null}

      <div className={`tw-relative tw-z-[10] tw-flex tw-justify-center`}>
        {renderSections()}
      </div>
    </div>
  );
};

export default RichTextWithImageWidget;
